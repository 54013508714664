* {
  margin: 0;
  padding: 0;
}

body {
  color: #FFF;
  background: #202125;
}

body, input, button, textarea {
  font: 600 18px 'Nunito', sans-serif;
}